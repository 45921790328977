.basket-wrapper {
	margin: 50px 0;
	@include breakpoint(tablet) {
		margin: 0;
	}
	h3 {
		font-size: 1.5em !important;
	}
	h3.final-sum {
		color: #05a4a1;
	}
	form {
		input[type="text"],
		input[type="number"],
		input[type="phone"],
		textarea {
			width: 100%;
			padding: 10px;
			border: 1px solid #aaa;
			font-size: 0.8em;
			margin: 5px 0;
		}
		textarea {
			min-height: 178px;
		}
		input[type="submit"] {
			background: #05a4a1;
			background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
			color: #FFF;
			border-radius: 3px;
			cursor: pointer;
			width: auto;
			border: 0 none;
			font-size: 0.9em;
			padding: 13px 25px;
			font-weight: bold;
			&:hover {
				background: #05a4a1;
			}
		}
	}
	.basket {
		.item {
			padding: 10px;
			background: #eee;
			color: #333;
			padding-bottom: 10px;
			margin-bottom: 25px;
			.left {
				display: none;
				.tire-wrapper {
					img {

					}
					small {

					}
				}
			}
			.center {
				@include breakpoint(tablet) {
					width: 66% !important;
				}
				
				margin: 0;
				.type {

				}
				.name {

				}
				.nubmers {
					display: block;
				}
				.icons {
					span {
						margin: 5px 10px 0 0;
						width: 30px;
						height: 25px;
						background: url("../images/sprite.png") no-repeat;
						padding: 0 0 0 24px;
						line-height: 22px;
						color: #555;
						font-size: 14px;
					}
					.kraftstoff {
    					background-position: 2px 2px;
					}
					.haftung {
    					background-position: 2px -38px;
					}
					.lautstaerke {
						background-position: 2px -79px;
					}
				}
			}
			.right {
				@include breakpoint(tablet) {
					width: 33% !important;
					float: right;
					text-align: right;
				}
				.col-6 {
					@include breakpoint(tablet) {
						text-align: center;
					}
				}
				.row-price {
					@include breakpoint(tablet) {
						float: left;
					}
					.price {
						.value {

						}
						.mwst {

						}
					}
				}
				.in-basket {
					display: none;
				}
				.number-wrapper {
					@include breakpoint(tablet) {
						text-align: center;
					}
					margin: 0 0 10px;
					label {
						display: block;
					}
					.plus,
					.minus {
						display: inline-block;
						width: 20px;
						height: 20px;
						background: #000;
						color: #fff;
						text-align: center;
						line-height: 20px;
						border-radius: 100%;
						margin: 0 5px;
						cursor: pointer;
					}
				}
			}
		}
	}
}