.nav-main {
	@include clearfix;
	width: 100%;
	float: left;
	background: #000;
	@include breakpoint(laptop) {
		background: none;
		width: auto;
		border-top: 0 none;
	}
	ul {
		display: none;
		.open & {
			//display: block;
		}
		@include breakpoint(laptop) {
			display: block;
		}
		li {
			list-style: none;
			text-align: center;
			@include breakpoint(laptop) {
				float: left;
				text-align: left;
			}
			a {
				color: #fff;
				opacity: 0.5;
				display: block;
				text-decoration: none;
				padding: 15px 0;
				&:hover {
					opacity: 1;
				}
				@include breakpoint(laptop) {
					padding: 25px;
					text-transform: uppercase;
					font-size: 1.5em;
				}
			}
			&:first-child {
				a {
					@include breakpoint(laptop) {
						padding-left: 0
					}
				}
				
			}
			&.active {
				a {
					opacity: 1;
				}
			}
		}
	}
}

.nav-toggle {
	float: right;
	cursor: pointer;
	vertical-align: top;
	display: inline-block;
	z-index: 999999;
	padding: 10px;
	@include breakpoint(tablet) {
		display: none;
	}
	.nav-opener {
		display: inline-block;
		vertical-align: top;
		float: right;
		width: 40px;
		height: 35px;
		position: relative;
		text-indent: -9999px;
		overflow: hidden;
		&:before,
		&:after,
		& span {
			background: #fff;
			border-radius: 0px;
			position: absolute;
			top: 19px;
			left: 3px;
			right: 3px;
			height: 4px;
			margin-top: -5px;
			transition: all 0.2s linear;
		}
		&:before {
			content: '';
			top: 8px;
			left: 30%;
		}
		&:after {
			content: '';
			top: 30px;
		}
		.open & span {
			opacity: 0;
		}
		.open &:after,
		.open &:before {
			background: #fff;
			transform: rotate(45deg);
			top: 20px;
			left: 5%;
			right: 5%;
		}
		.open &:after {
			transform: rotate(-45deg);
		}
	}
}