.custom-select-wrapper {
	display: block;
	position: relative;
	width: 100%;
	height: 42px;
	padding: 5px 8px;
	border: 1px solid #d8d8d8;
	border-radius: 3px;
	background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
	margin: 0 0 10px;
	&:before {
		content: "";
		position: absolute;
		border-collapse: separate;
		border-top-color: #58585a !important;
		top: 50%;
		pointer-events: none;
		margin-top: -3.5px;
		border: 7px solid transparent;
		right: 15px;
	}
	select {
		opacity: 0;
		filter: alpha(opacity=0);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		cursor: pointer;
		padding: 0;
		border: none;
	}
	.custom-select-value {
		line-height: 33px;
		font-size: 0.85em;
		padding: 0 5px;
	}
}

.form-tire-search {
	font-size: 1.1em;
	padding: 10px 10px 20px;
	background: #eee;
	color: #333;
	@include breakpoint(tablet) {
		padding: 15px;
		max-width: 450px;
		box-shadow: 0 2px 5px rgba(0,0,0,0.15);
		border-radius: 3px;
	}
	h3 {
		text-align: center;
		font-weight: bold;
		margin: 8px 0;
		@include breakpoint(tablet) {
			display: none;
		}

	}
	label {
		font-weight: bold;
		display: block;
		text-align: center;
	}
	input,
	select {
		width: 100%;
	}
	button {
		background: #05a4a1;
		background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
		color: #FFF;
		border-radius: 3px;
		cursor: pointer;
		width: 100%;
		border: 0 none;
		font-size: 0.9em;
		padding: 13px 10px;
		font-weight: bold;
		&:hover {
			background: #05a4a1;
		}
	}
	.select-wrapper-width,
	.select-wrapper-height {
		@include span(4);
	}
	.select-wrapper-inch {
		@include span(4 last);
	}
}