html {
  margin: 0;
  padding: 0;
  border: 0; }

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

body {
  line-height: 1.5;
  background: white; }

table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  float: none !important; }

table, th, td {
  vertical-align: middle; }

blockquote before, blockquote after, q before, q after {
  content: ''; }

a img {
  border: none; }

.custom-select-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 42px;
  padding: 5px 8px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
  margin: 0 0 10px; }
  .custom-select-wrapper:before {
    content: "";
    position: absolute;
    border-collapse: separate;
    border-top-color: #58585a !important;
    top: 50%;
    pointer-events: none;
    margin-top: -3.5px;
    border: 7px solid transparent;
    right: 15px; }
  .custom-select-wrapper select {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    cursor: pointer;
    padding: 0;
    border: none; }
  .custom-select-wrapper .custom-select-value {
    line-height: 33px;
    font-size: 0.85em;
    padding: 0 5px; }

.form-tire-search {
  font-size: 1.1em;
  padding: 10px 10px 20px;
  background: #eee;
  color: #333; }
  @media (min-width: 50em) {
    .form-tire-search {
      padding: 15px;
      max-width: 450px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 3px; } }
  .form-tire-search h3 {
    text-align: center;
    font-weight: bold;
    margin: 8px 0; }
    @media (min-width: 50em) {
      .form-tire-search h3 {
        display: none; } }
  .form-tire-search label {
    font-weight: bold;
    display: block;
    text-align: center; }
  .form-tire-search input, .form-tire-search select {
    width: 100%; }
  .form-tire-search button {
    background: #05a4a1;
    background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: 100%;
    border: 0 none;
    font-size: 0.9em;
    padding: 13px 10px;
    font-weight: bold; }
    .form-tire-search button:hover {
      background: #05a4a1; }
  .form-tire-search .select-wrapper-width, .form-tire-search .select-wrapper-height {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .form-tire-search .select-wrapper-inch {
    width: 32.20339%;
    float: right;
    margin-right: 0; }

.hidden {
  display: none; }

.wrapper {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px; }

.container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .container:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 50em) {
    .container {
      padding: 0 25px; } }

.no-padding {
  padding: 0 !important; }

.font-big {
  font-size: 1.3em;
  line-height: 1.6em; }
  @media (min-width: 50em) {
    .font-big {
      font-size: 1.6em;
      line-height: 1.8em; } }

.row {
  zoom: 1; }
  .row:before, .row:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .row:after {
    clear: both; }
  @media (min-width: 50em) {
    .row .col-3 {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-3:last-child {
      width: 23.72881%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-3.last-child {
      width: 23.72881%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-4 {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-4:last-child {
      width: 32.20339%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-4.last-child {
      width: 32.20339%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-5 {
      width: 40.67797%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-5:last-child {
      width: 40.67797%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-5.last-child {
      width: 40.67797%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-6 {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-6:last-child {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-6.last-child {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-7 {
      width: 57.62712%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-7:last-child {
      width: 57.62712%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-7.last-child {
      width: 57.62712%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-8 {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-8:last-child {
      width: 66.10169%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-8.last-child {
      width: 66.10169%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-9 {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .row .col-9:last-child {
      width: 74.57627%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 50em) {
    .row .col-9.last-child {
      width: 74.57627%;
      float: right;
      margin-right: 0; } }

.signature {
  font-family: 'Parisienne', cursive;
  font-size: 2em;
  color: #333; }

.image-responsive {
  max-width: 100%;
  margin: 0 auto;
  display: block; }

.image-responsive-full {
  width: 100%;
  display: block; }

.nav-main {
  zoom: 1;
  width: 100%;
  float: left;
  background: #000; }
  .nav-main:before, .nav-main:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .nav-main:after {
    clear: both; }
  @media (min-width: 64em) {
    .nav-main {
      background: none;
      width: auto;
      border-top: 0 none; } }
  .nav-main ul {
    display: none; }
    @media (min-width: 64em) {
      .nav-main ul {
        display: block; } }
    .nav-main ul li {
      list-style: none;
      text-align: center; }
      @media (min-width: 64em) {
        .nav-main ul li {
          float: left;
          text-align: left; } }
      .nav-main ul li a {
        color: #fff;
        opacity: 0.5;
        display: block;
        text-decoration: none;
        padding: 15px 0; }
        .nav-main ul li a:hover {
          opacity: 1; }
        @media (min-width: 64em) {
          .nav-main ul li a {
            padding: 25px;
            text-transform: uppercase;
            font-size: 1.5em; } }
      @media (min-width: 64em) {
        .nav-main ul li:first-child a {
          padding-left: 0; } }
      .nav-main ul li.active a {
        opacity: 1; }

.nav-toggle {
  float: right;
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
  z-index: 999999;
  padding: 10px; }
  @media (min-width: 50em) {
    .nav-toggle {
      display: none; } }
  .nav-toggle .nav-opener {
    display: inline-block;
    vertical-align: top;
    float: right;
    width: 40px;
    height: 35px;
    position: relative;
    text-indent: -9999px;
    overflow: hidden; }
    .nav-toggle .nav-opener:before, .nav-toggle .nav-opener:after, .nav-toggle .nav-opener span {
      background: #fff;
      border-radius: 0px;
      position: absolute;
      top: 19px;
      left: 3px;
      right: 3px;
      height: 4px;
      margin-top: -5px;
      transition: all 0.2s linear; }
    .nav-toggle .nav-opener:before {
      content: '';
      top: 8px;
      left: 30%; }
    .nav-toggle .nav-opener:after {
      content: '';
      top: 30px; }
    .open .nav-toggle .nav-opener span {
      opacity: 0; }
    .open .nav-toggle .nav-opener:after, .open .nav-toggle .nav-opener:before {
      background: #fff;
      transform: rotate(45deg);
      top: 20px;
      left: 5%;
      right: 5%; }
    .open .nav-toggle .nav-opener:after {
      transform: rotate(-45deg); }

.owl-controls {
  position: absolute;
  z-index: 5;
  bottom: -55px;
  left: 0;
  width: 100%; }
  .owl-controls .owl-pagination {
    width: 100%;
    text-align: center; }
    .owl-controls .owl-pagination .owl-page {
      width: 20px;
      height: 20px;
      background: #eee;
      display: inline-block;
      border-radius: 100%;
      margin: 5px;
      border: 1px solid #ccc; }
      .owl-controls .owl-pagination .owl-page.active, .owl-controls .owl-pagination .owl-page:hover {
        background: #05a4a1;
        border: 1px solid #05a4a1; }

.products .list .item {
  color: #333;
  margin: 10px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #eee; }
  .products .list .item .left img {
    width: 100%;
    height: auto; }
  .products .list .item .left small {
    display: block;
    width: 100%;
    text-align: center;
    color: #aaa; }
  .products .list .item .center .type {
    display: block;
    color: #888;
    font-size: 0.9em; }
  .products .list .item .center .name {
    display: block;
    font-size: 1.6em;
    line-height: 1.2em;
    margin: 0 0 5px; }
  .products .list .item .center .icons span {
    margin: 5px 10px 0 0;
    width: 30px;
    height: 25px;
    background: url("../images/sprite.png") no-repeat;
    padding: 0 0 0 24px;
    line-height: 22px;
    color: #555;
    font-size: 14px; }
  .products .list .item .center .kraftstoff {
    background-position: 2px 2px !important; }
  .products .list .item .center .haftung {
    background-position: 2px -38px !important; }
  .products .list .item .center .lautstaerke {
    background-position: 2px -79px !important; }
  .products .list .item .center .lagerbestand {
    display: block;
    color: #666;
    font-size: 0.9em;
    margin-top: 10px; }
  .products .list .item .right .row-price {
    margin: 20px 0 0; }
    @media (min-width: 50em) {
      .products .list .item .right .row-price {
        margin: 0; } }
  .products .list .item .right .price {
    display: block;
    font-size: 2em;
    color: #05a4a1;
    margin: 0px 0 30px; }
    @media (min-width: 50em) {
      .products .list .item .right .price {
        float: right; } }
  .products .list .item .right .mwst {
    display: block;
    color: #aaa;
    font-size: 13px;
    margin: 10px 0 0px; }
  .products .list .item .right .in-basket {
    display: block;
    text-decoration: none;
    text-align: center;
    background: #05a4a1;
    background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: 100%;
    border: 0 none;
    font-size: 0.9em;
    padding: 10px 10px;
    font-weight: bold; }
    .products .list .item .right .in-basket:hover {
      background: #05a4a1; }
  .products .list .item .right .number-wrapper, .products .list .item .right .remove-item {
    display: none; }

.basket-wrapper {
  margin: 50px 0; }
  @media (min-width: 50em) {
    .basket-wrapper {
      margin: 0; } }
  .basket-wrapper h3 {
    font-size: 1.5em !important; }
  .basket-wrapper h3.final-sum {
    color: #05a4a1; }
  .basket-wrapper form input[type="text"], .basket-wrapper form input[type="number"], .basket-wrapper form input[type="phone"], .basket-wrapper form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #aaa;
    font-size: 0.8em;
    margin: 5px 0; }
  .basket-wrapper form textarea {
    min-height: 178px; }
  .basket-wrapper form input[type="submit"] {
    background: #05a4a1;
    background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: auto;
    border: 0 none;
    font-size: 0.9em;
    padding: 13px 25px;
    font-weight: bold; }
    .basket-wrapper form input[type="submit"]:hover {
      background: #05a4a1; }
  .basket-wrapper .basket .item {
    padding: 10px;
    background: #eee;
    color: #333;
    padding-bottom: 10px;
    margin-bottom: 25px; }
    .basket-wrapper .basket .item .left {
      display: none; }
    .basket-wrapper .basket .item .center {
      margin: 0; }
      @media (min-width: 50em) {
        .basket-wrapper .basket .item .center {
          width: 66% !important; } }
      .basket-wrapper .basket .item .center .nubmers {
        display: block; }
      .basket-wrapper .basket .item .center .icons span {
        margin: 5px 10px 0 0;
        width: 30px;
        height: 25px;
        background: url("../images/sprite.png") no-repeat;
        padding: 0 0 0 24px;
        line-height: 22px;
        color: #555;
        font-size: 14px; }
      .basket-wrapper .basket .item .center .icons .kraftstoff {
        background-position: 2px 2px; }
      .basket-wrapper .basket .item .center .icons .haftung {
        background-position: 2px -38px; }
      .basket-wrapper .basket .item .center .icons .lautstaerke {
        background-position: 2px -79px; }
    @media (min-width: 50em) {
      .basket-wrapper .basket .item .right {
        width: 33% !important;
        float: right;
        text-align: right; } }
    @media (min-width: 50em) {
      .basket-wrapper .basket .item .right .col-6 {
        text-align: center; } }
    @media (min-width: 50em) {
      .basket-wrapper .basket .item .right .row-price {
        float: left; } }
    .basket-wrapper .basket .item .right .in-basket {
      display: none; }
    .basket-wrapper .basket .item .right .number-wrapper {
      margin: 0 0 10px; }
      @media (min-width: 50em) {
        .basket-wrapper .basket .item .right .number-wrapper {
          text-align: center; } }
      .basket-wrapper .basket .item .right .number-wrapper label {
        display: block; }
      .basket-wrapper .basket .item .right .number-wrapper .plus, .basket-wrapper .basket .item .right .number-wrapper .minus {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #000;
        color: #fff;
        text-align: center;
        line-height: 20px;
        border-radius: 100%;
        margin: 0 5px;
        cursor: pointer; }

* {
  box-sizing: border-box;
  outline: 0 none; }

body {
  font: 300 14px/1.6em 'Roboto', sans-serif;
  color: #838993;
  overflow-y: scroll; }
  @media (min-width: 64em) {
    body {
      margin-top: 75px;
      font: 300 16px/1.6em 'Roboto', sans-serif; } }

a {
  color: #05a4a1; }
  a:hover {
    color: #048b89; }

.header-main {
  background: #232323;
  color: #fff;
  width: 100%; }
  @media (min-width: 64em) {
    .header-main {
      position: fixed;
      top: 0;
      z-index: 9999; } }

.header-sub .container {
  padding: 15px 20px 10px; }

.button-shop {
  background: #05a4a1;
  background-image: -webkit-gradient(linear, left top, left bottom, left, right);
  background-image: -webkit-linear-gradient(top, #05a4a1 0%, #107271 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, left, right);
  background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
  color: #fff;
  padding: 10px 15px;
  font-size: 1.25em;
  font-weight: 100;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  border-radius: 0; }
  @media (min-width: 50em) {
    .button-shop {
      width: auto;
      display: inline-block;
      font-size: 1em;
      border-radius: 3px;
      float: right;
      margin: 15px 0; } }
  .button-shop:hover {
    color: #fff;
    background: #05a4a1; }
  .button-shop i {
    font-size: 1.5em;
    margin: 0 5px 0 0; }

.logo {
  display: block; }
  .logo img {
    max-width: 400px;
    display: block;
    margin: 0 auto; }
  @media (min-width: 50em) {
    .logo {
      float: left;
      width: 400px; } }

.phone {
  color: #444;
  text-decoration: none;
  font-size: 1.65em;
  font-weight: bold;
  text-align: center;
  display: block; }
  @media (min-width: 50em) {
    .phone {
      float: right;
      line-height: 80px; } }
  @media (max-width: 50em) {
    .phone {
      background: #05a4a1;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      font-weight: normal;
      font-size: 1.8em;
      margin: 10px auto;
      max-width: 320px; } }
  .phone:hover {
    color: #444; }
    @media (max-width: 50em) {
      .phone:hover {
        color: #fff; } }

.emotion {
  background: #000;
  position: relative;
  z-index: 1;
  margin: 0 0 63px; }
  .emotion .owl-item {
    overflow: hidden; }
    .emotion .owl-item img {
      display: block;
      width: 200%;
      margin-left: -50%; }
      @media (min-width: 50em) {
        .emotion .owl-item img {
          width: 100%;
          margin-left: 0; } }

.content h2 {
  color: #333;
  font-size: 1.6em;
  font-weight: 700;
  margin: 0 0 5px; }
.content h3 {
  color: #333;
  font-size: 1.3em;
  font-weight: 400;
  margin: 0 0 5px; }
.content h4 {
  color: #333;
  font-size: 1.1em;
  font-weight: 400;
  margin: 0 0 5px; }
.content p {
  margin: 10px 0 20px; }

@media (min-width: 50em) {
  .content-search {
    padding: 30px 0 60px; } }
.content-search .col-7 {
  padding: 20px; }
  @media (min-width: 50em) {
    .content-search .col-7 {
      padding: 0; } }

@media (min-width: 50em) {
  .content-gallery {
    padding: 40px 0; }
    .content-gallery .row {
      margin: 20px 0; } }

.content-block .bg {
  padding: 100px 0;
  background: rgba(0, 0, 0, 0.5); }
.content-block.bigtext {
  color: #fff;
  background: url("../images/bg_1sm.jpg");
  background-position: center 100%; }
  @media (min-width: 50em) {
    .content-block.bigtext {
      background-size: cover;
      background-attachment: fixed; } }
  .content-block.bigtext h2, .content-block.bigtext h3 {
    color: #fff;
    text-align: center; }
  .content-block.bigtext h2 {
    font-size: 2em;
    line-height: 1.4em;
    text-transform: uppercase; }
    @media (min-width: 50em) {
      .content-block.bigtext h2 {
        font-size: 4.5em;
        max-width: 800px;
        margin: 0 auto; } }
  .content-block.bigtext h3 {
    font-size: 1.3em;
    font-weight: 400;
    font-style: italic;
    line-height: 23px;
    letter-spacing: -.6px;
    text-transform: none; }
.content-block.services {
  margin: 50px 0 100px; }
  @media (min-width: 50em) {
    .content-block.services .col {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .content-block.services .col:last-child {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }
  .content-block.services .col .service {
    zoom: 1;
    position: relative;
    padding: 25px 50px; }
    .content-block.services .col .service:before, .content-block.services .col .service:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .content-block.services .col .service:after {
      clear: both; }
    .content-block.services .col .service h3 {
      font-size: 1.5em; }
      @media (min-width: 50em) {
        .content-block.services .col .service h3 {
          font-size: 1.75em; } }
      .content-block.services .col .service h3.single {
        margin-top: 12%; }
    .content-block.services .col .service ul {
      margin: 5px 0;
      padding: 0; }
      .content-block.services .col .service ul li {
        list-style: none; }
    .content-block.services .col .service .icon {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .content-block.services .col .service .icon img {
        width: 100%;
        height: auto; }
    .content-block.services .col .service .text {
      width: 66.10169%;
      float: right;
      margin-right: 0;
      position: relative;
      text-align: center; }
      .content-block.services .col .service .text .inside {
        /*
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						*/ }
.content-block.footer {
  color: #fff;
  background: url("../images/bg_2.jpg");
  background-position: center 100%;
  text-align: center; }
  @media (min-width: 50em) {
    .content-block.footer {
      background-size: cover;
      background-attachment: fixed; } }
  .content-block.footer h2, .content-block.footer h3 {
    color: #fff;
    text-align: center; }
  .content-block.footer h2 {
    font-size: 2.5em;
    text-transform: uppercase;
    margin: 0 0 15px; }
  .content-block.footer h3 {
    font-size: 1.3em;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -.6px;
    text-transform: none; }
.content-block.logos .row {
  margin: 25px 0; }
.content-block.logos img {
  max-width: 200px; }
@media (max-width: 50em) {
  .content-block.logos .col-3 {
    width: 50%;
    float: left;
    height: 50px; }
    .content-block.logos .col-3 img {
      display: block;
      width: 75%;
      height: auto; } }

.row-teaser .col {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 100%;
  overflow: hidden; }
  @media (max-width: 50em) {
    .row-teaser .col {
      margin: 40px 50px; } }
  .row-teaser .col img {
    width: 100%;
    border-radius: 100%;
    display: block; }
  .row-teaser .col .text {
    display: block;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-size: 1.75em;
    opacity: 1; }
  .row-teaser .col .bg {
    display: block;
    position: absolute;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%; }
  @media (min-width: 50em) {
    .row-teaser .col .text, .row-teaser .col .bg {
      display: none; }
    .row-teaser .col:hover .text, .row-teaser .col:hover .bg {
      display: block; } }

.content-subpage {
  padding: 50px 15px; }
  .content-subpage ul {
    padding: 0 0 0 25px; }

@media (max-width: 50em) {
  .form-contact {
    margin: 50px 0; } }
.form-contact input[type="text"], .form-contact textarea {
  width: 100%;
  padding: 10px;
  font-size: 0.9em;
  border: 1px solid #ccc; }
  @media (max-width: 50em) {
    .form-contact input[type="text"], .form-contact textarea {
      margin: 10px 0; } }
.form-contact textarea {
  min-height: 180px;
  margin: 20px 0; }
.form-contact button {
  background: #05a4a1;
  background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
  color: #FFF;
  border-radius: 3px;
  cursor: pointer;
  border: 0 none;
  font-size: 0.9em;
  padding: 15px 30px;
  font-weight: bold; }
  .form-contact button:hover {
    background: #05a4a1; }

.services2 .service2 img {
  padding: 10px; }
.services2 .service2 h3 {
  margin: 0;
  line-height: 80px;
  text-align: center; }
  @media (min-width: 50em) {
    .services2 .service2 h3 {
      text-align: left; } }

.footer-main {
  background: #000;
  color: #aaa;
  padding: 15px 0 20px; }
  @media (max-width: 50em) {
    .footer-main {
      text-align: center; }
      .footer-main h3 {
        margin-top: 25px !important; } }
  @media (min-width: 64em) {
    .footer-main {
      padding: 30px 0 50px;
      margin-top: 100px; } }
  .footer-main a {
    color: #aaa; }
    .footer-main a:hover {
      color: #fff; }
  .footer-main h3 {
    font-weight: bold;
    font-size: 1.5em;
    color: #666;
    margin: 0 0 10px; }
    @media (min-width: 64em) {
      .footer-main h3 {
        margin: 0 0 25px; } }
  .footer-main .nav-footer ul {
    zoom: 1; }
    .footer-main .nav-footer ul:before, .footer-main .nav-footer ul:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .footer-main .nav-footer ul:after {
      clear: both; }
    @media (max-width: 50em) {
      .footer-main .nav-footer ul {
        text-align: center; } }
    .footer-main .nav-footer ul li {
      list-style: none;
      text-align: center; }
      @media (min-width: 64em) {
        .footer-main .nav-footer ul li {
          text-align: left; } }
      @media (max-width: 50em) {
        .footer-main .nav-footer ul li {
          display: inline-block;
          margin: 0 5px; } }
      .footer-main .nav-footer ul li a {
        text-decoration: none;
        display: block;
        padding: 10px 0; }
        @media (min-width: 64em) {
          .footer-main .nav-footer ul li a {
            padding: 0; } }
  .footer-main .nav-social a {
    display: inline-block;
    margin: 0 5px 0 0; }
  .footer-main .nav-social img {
    display: block; }

