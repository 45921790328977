* {
	box-sizing: border-box;
	outline: 0 none;
}

body {
	font: 300 14px/1.6em 'Roboto', sans-serif;
	color: #838993;
	overflow-y: scroll;
	@include breakpoint(laptop) {
		margin-top: 75px;
		font: 300 16px/1.6em 'Roboto', sans-serif;
	}
}

a {
	color: #05a4a1;
	&:hover {
		color: darken(#05a4a1, 5%);
	}
}

.header-main {
	background: #232323;
	color: #fff;
	width: 100%;
	@include breakpoint(laptop) {
		position: fixed;
		top: 0;
		z-index: 9999;
	}
}

.header-sub {
	.container {
		padding: 15px 20px 10px;
	}
}

.button-shop {
	background: #05a4a1;
	background-image: -webkit-gradient(linear, left top, left bottom, left, right);
	background-image: -webkit-linear-gradient(top, #05a4a1 0%, #107271 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#05a4a1), to(#107271));
	background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
	color: #fff;
	padding: 10px 15px;
	font-size: 1.25em;
	font-weight: 100;
	text-decoration: none;
	text-transform: uppercase;
	display: block;
	float: left;
	width: 100%;
	text-align: center;
	border-radius: 0;
	@include breakpoint(tablet) {
		width: auto;
		display: inline-block;
		font-size: 1em;
		border-radius: 3px;
		float: right;
		margin: 15px 0;
	}
	&:hover {
		color: #fff;
		background: #05a4a1;
	}
	i {
		font-size: 1.5em;
		margin: 0 5px 0 0;
	}
}

.logo {
	display: block;
	img {
		max-width: 400px;
		display: block;
		margin: 0 auto;
	}
	@include breakpoint(tablet) {
		float: left;
		width: 400px;
	}
}

.phone {
	color: #444;
	text-decoration: none;
	font-size: 1.65em;
	font-weight: bold;
	text-align: center;
	display: block;
	@include breakpoint(tablet) {
		float: right;
		line-height: 80px;
	}
	@include breakpoint(mobileonly) {
		background: #05a4a1;
		color: #fff;
		padding: 10px;
		border-radius: 5px;
		font-weight: normal;
		font-size: 1.8em;
		margin: 10px auto;
		max-width: 320px;
	}
	&:hover {
		color: #444;
		@include breakpoint(mobileonly) {
			color: #fff;
		}
	}
}

.emotion {
	background: #000;
	position: relative;
	z-index: 1;
	margin: 0 0 63px;
	.owl-item {
		overflow: hidden;
		img {
			display: block;
			width: 200%;
			margin-left: -50%;
			@include breakpoint(tablet) {
				width: 100%;
				margin-left: 0;
			}
		}
	}
}

.content {
	h2 {
		color: #333;
		font-size: 1.6em;
		font-weight: 700;
		margin: 0 0 5px;
	}
	h3 {
		color: #333;
		font-size: 1.3em;
		font-weight: 400;
		margin: 0 0 5px;
	}
	h4 {
		color: #333;
		font-size: 1.1em;
		font-weight: 400;
		margin: 0 0 5px;
	}
	p {
		margin: 10px 0 20px;
	}
}

.content-search {
	@include breakpoint(tablet) {
		padding: 30px 0 60px;
	}
	.col-7 {
		padding: 20px;
		@include breakpoint(tablet) {
			padding: 0;
		}
	}
}

.content-gallery {
	@include breakpoint(tablet) {
		padding: 40px 0;
		.row {
			margin: 20px 0;
		}
	}
}

.content-block {
	.bg {
		padding: 100px 0;
		background: rgba(0,0,0,0.5);
	}
	&.bigtext {
		color: #fff;
		background: url("../images/bg_1sm.jpg");
		background-position: center 100%;
		@include breakpoint(tablet){
			background-size: cover;
			background-attachment: fixed;
		}
		h2,
		h3 {
			color: #fff;
			text-align: center;
		}
		h2 {
			font-size: 2em;
			line-height: 1.4em;
			text-transform: uppercase;
			@include breakpoint(tablet){
				font-size: 4.5em;
				max-width: 800px;
				margin: 0 auto;
			}
		}
		h3 {
			font-size: 1.3em;
			font-weight: 400;
			font-style: italic;
			line-height: 23px;
			letter-spacing: -.6px;
			text-transform: none;
		}
	}
	&.services {
		margin: 50px 0 100px;
		.col {
			@include breakpoint(tablet) {
				@include span(6);
			}
			&:last-child {
				@include breakpoint(tablet) {
					@include span(6 last);
				}
			}
			.service {
				@include clearfix;
				position: relative;
				padding: 25px 50px;
				h3 {
					font-size: 1.5em;
					@include breakpoint(tablet) {
						font-size: 1.75em;
					}
					&.single {
						margin-top: 12%;
					}
				}
				p {

				}
				ul {
					margin: 5px 0;
					padding: 0;
					li {
						list-style: none;
					}
				}
				.icon {
					@include span(4);
					img {
						width: 100%;
						height: auto;
					}
				}
				.text {
					@include span(8 last);
					position: relative;
					text-align: center;
					.inside {
						/*
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						*/
					}
				}
			}
		}
	}
	&.footer {
		color: #fff;
		background: url("../images/bg_2.jpg");
		background-position: center 100%;
		text-align: center;
		@include breakpoint(tablet){
			background-size: cover;
			background-attachment: fixed;
		}
		h2,
		h3 {
			color: #fff;
			text-align: center;
		}
		h2 {
			font-size: 2.5em;
			text-transform: uppercase;
			margin: 0 0 15px;
		}
		h3 {
			font-size: 1.3em;
			font-weight: 400;
			line-height: 23px;
			letter-spacing: -.6px;
			text-transform: none;
		}
	}
	&.logos {
		.row {
			margin: 25px 0;
		}
		img {
			max-width: 200px;
		}
		@include breakpoint(mobileonly){
			.col-3 {
				width: 50%;
				float: left;
				height: 50px;
				img {
					display: block;
					width: 75%;
					height: auto;
				}
			}
		}
	}
}

.row-teaser {
	.col {
		position: relative;
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 10px;
		background: rgba(0, 0, 0, .35);
		border-radius: 100%;
		overflow: hidden;
		@include breakpoint(mobileonly) {
			margin: 40px 50px;
		}
		img {
			width: 100%;
			border-radius: 100%;
			display: block;
		}
		.text {
			display: block;
			position: absolute;
			z-index: 2;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			text-transform: uppercase;
			color: #fff;
			font-weight: bold;
			font-size: 1.75em;
			opacity: 1;
		}
		.bg {
			display: block;
			position: absolute;
			top: 0;
			z-index: 1;
			background: rgba(0, 0, 0, .5);
			width: 100%;
			height: 100%;
		}
		@include breakpoint(tablet) {
			.text,
			.bg {
				display: none;
			}
			&:hover {
				.text,
				.bg {
					display: block;
				}
			}
		}
	}
}

.content-subpage {
	padding: 50px 15px;
	ul {
		padding: 0 0 0 25px;
	}
}

.form-contact {
	@include breakpoint(mobileonly) {
		margin: 50px 0;
	}
	input[type="text"],
	textarea {
		width: 100%;
		padding: 10px;
		font-size: 0.9em;
		border: 1px solid #ccc;
		@include breakpoint(mobileonly) {
			margin: 10px 0;
		}
	}
	textarea {
		min-height: 180px;
		margin: 20px 0;
	}
	button {
		background: #05a4a1;
		background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
		color: #FFF;
		border-radius: 3px;
		cursor: pointer;
		border: 0 none;
		font-size: 0.9em;
		padding: 15px 30px;
		font-weight: bold;
		&:hover {
			background: #05a4a1;
		}
	}
}

.services2 {
	.service2 {
		img {
			padding: 10px;
		}
		h3 {
			margin: 0;
			line-height: 80px;
			text-align: center;
			@include breakpoint(tablet){
				text-align: left;
			}
		}
	}
}

.footer-main {
	background: #000;
	color: #aaa;
	padding: 15px 0 20px;
	@include breakpoint(mobileonly) {
		text-align: center;
		h3 {
			margin-top: 25px !important;
		}
	}
	@include breakpoint(laptop) {
		padding: 30px 0 50px;
		margin-top: 100px;
	}
	a {
		color: #aaa;
		&:hover {
			color: #fff;
		}
	}
	h3 {
		font-weight: bold;
		font-size: 1.5em;
		color: #666;
		margin: 0 0 10px;
		@include breakpoint(laptop) {
			margin: 0 0 25px;
		}
	}
	.nav-footer {
		ul {
			@include clearfix;
			@include breakpoint(mobileonly) {
				text-align: center;
			}
			li {
				list-style: none;
				text-align: center;
				@include breakpoint(laptop) {
					text-align: left;
				}
				@include breakpoint(mobileonly) {
					display: inline-block;
					margin: 0 5px;
				}
				a {
					text-decoration: none;
					display: block;
					padding: 10px 0;
					@include breakpoint(laptop) {
						padding: 0;
					}
				}
			}
		}
	}
	.nav-social {
		a {
			display: inline-block;
			margin: 0 5px 0 0;
		}
		img {
			display: block;
		}
	}
}