.owl-controls {
	position: absolute;
	z-index: 5;
	bottom: -55px;
	left: 0;
	width: 100%;
	.owl-pagination {
		width: 100%;
		text-align: center;
		.owl-page {
			width: 20px;
			height: 20px;
			background: #eee;
			display: inline-block;
			border-radius: 100%;
			margin: 5px;
			border: 1px solid #ccc;
			&.active,
			&:hover {
				background: #05a4a1;
				border: 1px solid #05a4a1;
			}
		}
	}
}