.hidden {
	display: none;
}

.wrapper {
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 15px;
}

.container {
	@include container;
	@include breakpoint(tablet) {
		padding: 0 25px;
	}
}

.no-padding {
	padding: 0 !important;
}

.font-big {
	font-size: 1.3em;
	line-height: 1.6em;
	@include breakpoint(tablet) {
		font-size: 1.6em;
		line-height: 1.8em;
	}
}

.row {
	@include clearfix;
	.col-3 {
		@include breakpoint(tablet) {
			@include span(3);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(3 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(3 last);
			}
		}
	}
	.col-4 {
		@include breakpoint(tablet) {
			@include span(4);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(4 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(4 last);
			}
		}
	}
	.col-5 {
		@include breakpoint(tablet) {
			@include span(5);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(5 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(5 last);
			}
		}
	}
	.col-6 {
		@include breakpoint(tablet) {
			@include span(6);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(6 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(6 last);
			}
		}
	}
	.col-7 {
		@include breakpoint(tablet) {
			@include span(7);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(7 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(7 last);
			}
		}
	}
	.col-8 {
		@include breakpoint(tablet) {
			@include span(8);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(8 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(8 last);
			}
		}
	}
	.col-9 {
		@include breakpoint(tablet) {
			@include span(9);
		}
		&:last-child {
			@include breakpoint(tablet) {
				@include span(9 last);
			}
		}
		&.last-child {
			@include breakpoint(tablet) {
				@include span(9 last);
			}
		}
	}
}

.signature {
	font-family: 'Parisienne', cursive;
	font-size: 2em;
	color: #333;
}

.image-responsive {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}

.image-responsive-full {
	width: 100%;
	display: block;
}