.products {
	.list {
		.item {
			color: #333;
			margin: 10px 0;
			padding: 10px 0 20px;
			border-bottom: 1px solid #eee;
			.left {
				img {
					width: 100%;
					height: auto;
				}
				small {
					display: block;
					width: 100%;
					text-align: center;
					color: #aaa;
				}
			}
			.center {

				.type {
					display: block;
					color: #888;
					font-size: 0.9em;
				}
				.name {
					display: block;
					font-size: 1.6em;
					line-height: 1.2em;
					margin: 0 0 5px;
				}
				.icons span {
					margin: 5px 10px 0 0;
					width: 30px;
					height: 25px;
					background: url("../images/sprite.png") no-repeat;
					padding: 0 0 0 24px;
					line-height: 22px;
					color: #555;
					font-size: 14px;
				}
				.kraftstoff {
					background-position: 2px 2px !important;
				}
				.haftung {
					background-position: 2px -38px !important;
				}
				.lautstaerke {
					background-position: 2px -79px !important;
				}
				.lagerbestand {
					display: block;
					color: #666;
					font-size: 0.9em;
					margin-top: 10px;
				}

			}
			.right {
				.row-price {
					margin: 20px 0 0;
					@include breakpoint(tablet) {
						margin: 0;
					}
				}
				.price {
					display: block;
					font-size: 2em;
					color: #05a4a1;
					margin: 0px 0 30px;
					@include breakpoint(tablet) {
						float: right;
					}
				}
				.mwst {
					display: block;
					color: #aaa;
					font-size: 13px;
					margin: 10px 0 0px;
				}
				.in-basket {
					display: block;
					text-decoration: none;
					text-align: center;
					background: #05a4a1;
					background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
					color: #FFF;
					border-radius: 3px;
					cursor: pointer;
					width: 100%;
					border: 0 none;
					font-size: 0.9em;
					padding: 10px 10px;
					font-weight: bold;
					&:hover {
						background: #05a4a1;
					}
				}
				.number-wrapper,
				.remove-item {
					display: none;
				}
			}
		}
	}
}